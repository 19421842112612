<template>
  <div>
    <ros-status :ros="this.ros"></ros-status>
    <ros ref="rosObject" :url="this.rosIp" @getRosObject="setRos"></ros>
    <b-card>
      <robot-form v-on:getRobot="setRosIp"></robot-form>
      <b-button @click="reconnection" variant="primary">再接続</b-button>
    </b-card>
    <b-card>
      <b-row>
        <h3>畝検知・ステーションIN/OUT</h3>
      </b-row>
      <b-row>
        <b-button variant="outline-primary" v-on:click="uneRun(true)">畝検知移動開始</b-button>
        <b-button variant="outline-danger" v-on:click="uneRun(false)">畝検知移動停止</b-button>
        <b-button variant="outline-primary" v-on:click="stationIn(true)">ステーションIN開始</b-button>
        <b-button variant="outline-danger" v-on:click="stationIn(false)">ステーションIN停止</b-button>
        <b-button variant="outline-primary" v-on:click="stationOut(true)">ステーションOUT開始</b-button>
        <b-button variant="outline-danger" v-on:click="stationOut(false)">ステーションOUT停止</b-button>
      </b-row>
    </b-card>
    <b-card>
      <b-row>
        <h3>地磁気センサ設定</h3>
      </b-row>
      <b-row>
        <b-button variant="primary" v-on:click="run(true)">自動キャリブレーション開始</b-button>
        <b-button variant="danger" v-on:click="run(false)">停止</b-button>
      </b-row>
      <b-row>
        <plotly :data="protData" :layout="layout" :display-mode-bar="true" />
      </b-row>
      <b-row>
        <b-button variant="outline-dark" v-on:click="clear()">クリア</b-button>
        <b-button variant="outline-primary" v-on:click="setIntervalMagneticField">取得開始</b-button>
        <b-button variant="outline-danger" v-on:click="clearIntervalMagneticField">取得終了</b-button>
        <b-button
          variant="outline-dark"
          v-on:click="
            offsetClear();
            update();
          "
        >オフセットクリア</b-button>
        <b-button
          variant="outline-info"
          v-on:click="
            offsetCalc();
            update();
          "
        >オフセット計測・設定</b-button>
        <b-button variant="outline-dark" v-on:click="saveAzimuthOffset(0)">方位角クリア</b-button>
        <b-button variant="outline-info" v-on:click="saveAzimuthOffset(azimuth)">方位角設定</b-button>
      </b-row>
      <b-row>
        <table class="table">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>最大値</th>
              <th>最小値</th>
              <th>オフセット</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>x軸</th>
              <td>
                <label>{{ max_xaxis }}</label>
              </td>
              <td>
                <label>{{ min_xaxis }}</label>
              </td>
              <td>
                <label>{{ offset.x }}</label>
              </td>
            </tr>
            <tr>
              <th>y軸</th>
              <td>
                <label>{{ max_yaxis }}</label>
              </td>
              <td>
                <label>{{ min_yaxis }}</label>
              </td>
              <td>
                <label>{{ offset.y }}</label>
              </td>
            </tr>
          </tbody>
        </table>
      </b-row>
      <b-row>
        <b-button variant="outline-primary" v-on:click="setIntervalInclinationAngle">傾斜角取得開始</b-button>
        <b-button variant="outline-danger" v-on:click="clearIntervalInclinationAngle">傾斜角取得終了</b-button>
        <b-button variant="outline-dark" v-on:click="saveInclinationAngleOffset(0, 0)">傾斜角オフセットクリア</b-button>
        <b-button
          variant="outline-info"
          v-on:click="saveInclinationAngleOffset(inclination_angle.x, inclination_angle.y)"
        >傾斜角オフセット設定</b-button>
      </b-row>
      <b-row>
        <table class="table">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>角度</th>
              <th>オフセット</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>x角度</th>
              <td>
                <label>{{ inclination_angle.x }}</label>
              </td>
              <td>
                <label>{{ offset_x_angle }}</label>
              </td>
            </tr>
            <tr>
              <th>y角度</th>
              <td>
                <label>{{ inclination_angle.y }}</label>
              </td>
              <td>
                <label>{{ offset_y_angle }}</label>
              </td>
            </tr>
          </tbody>
        </table>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import ROSLIB from "roslib";
import { Plotly } from "vue-plotly";

import Ros from "../components/Ros";
import RobotForm from "../components/form/RobotForm";
import RosStatus from "../components/RosStatus";

export default {
  components: {
    Ros,
    RobotForm,
    RosStatus,
    Plotly
  },
  data() {
    return {
      ros: null,
      rosIp: null,
      hostAndPort: "",
      subMagnetometor: null,
      azimuth: 0,
      max_xaxis: 0,
      min_xaxis: 0,
      max_yaxis: 0,
      min_yaxis: 0,
      mag_x: [],
      mag_y: [],
      mag_z: [],
      offset: {
        x: 0,
        y: 0
      },
      offset_xaxis: 0,
      offset_yaxis: 0,
      offset_x_angle: 0,
      offset_y_angle: 0,
      inclination_angle: {
        x: 0,
        y: 0
      },
      protData: [],
      data: {
        name: "mag",
        x: [],
        y: [],
        z: [],
        mode: "markers",
        type: "scatter",
        // type: 'scatter3d',
        marker: {
          color: "blue",
          size: 5,
          // size: 2,
          opacity: 0.7
        }
      },
      origin: {
        name: "origin",
        x: [],
        y: [],
        z: [],
        mode: "markers",
        type: "scatter",
        // type: 'scatter3d',
        marker: {
          color: "red",
          size: 5,
          // size: 2,
          opacity: 0.7
        },
        xaxis: "x",
        yaxis: "y",
        zaxis: "z"
      },
      polar: {
        type: "scatterpolar",
        mode: "lines+markers",
        r: [0, 1],
        theta: [0, 0],
        marker: {
          color: "red",
          symbol: "circle",
          size: 8
        },
        line: {
          color: "red"
        }
      },
      targetPolar: {
        type: "scatterpolar",
        mode: "lines+markers",
        r: [0, 1],
        theta: [0, 0],
        marker: {
          color: "blue",
          symbol: "circle",
          size: 8
        },
        line: {
          color: "blue"
        }
      },
      layout: {
        showlegend: false,
        height: 700,
        width: 1400,
        xaxis: {
          zeroline: true,
          range: [-100, 100],
          domain: [0, 0.45]
        },
        yaxis: {
          zeroline: true,
          range: [-100, 100],
          domain: [0, 1]
        },
        zaxis: {
          zeroline: true,
          range: [-100, 100]
        },
        polar: {
          radialaxis: {
            visible: false
          },
          angularaxis: {
            direction: "clockwise"
          },
          domain: {
            x: [0.7, 1],
            y: [0.2, 0.8]
          }
        },
        scene: {
          aspectmode: "manual",
          aspectratio: {
            x: 1,
            y: 1,
            z: 1
          },
          xaxis: {
            tickmode: [-100, -50, 0, 50, 100],
            range: [-100, 100]
          },
          yaxis: {
            tickmode: [-100, -50, 0, 50, 100],
            range: [-100, 100]
          },
          zaxis: {
            tickmode: [-100, -50, 0, 50, 100],
            range: [-100, 100]
          },
          domain: {
            x: [0, 0.45],
            y: [0, 1]
          }
        }
      },
      magneticFieldIntervalID: null,
      inclinationAngleIntervalID: null
    };
  },
  created() {
    this.init();
  },
  watch: {
    protData: {
      handler(val) {
        this.protData = val;
      },
      deep: true
    }
  },
  methods: {
    init() {
      this.protData = [this.data, this.origin, this.polar, this.targetPolar];
    },
    // --------------------------------
    // ロボットを選択
    // --------------------------------
    setRosIp(value) {
      this.rosIp = value.ip;
    },
    // --------------------------------
    // ロボット接続
    // --------------------------------
    setRos(value) {
      this.ros = value;
    },
    // --------------------------------
    // ロボット再接続
    // --------------------------------
    reconnection() {
      this.$refs.rosObject.init();
    },
    // --------------------------------
    // ロボット接続 成功
    // --------------------------------
    rosConnected() {
      this.initOffset();
    },
    // --------------------------------
    // ロボット接続 失敗
    // --------------------------------
    rosUnConnected() {
      // 初期化
    },
    update() {
      this.data.x = this.mag_x;
      this.data.y = this.mag_y;
      this.data.z = this.mag_z;
      if (this.offset.x != 0 || this.offset.y != 0) {
        let setOffset = function(arr, offset) {
          let newArr = [];
          for (let i = 0; i < arr.length; i++) {
            newArr.push(arr[i] - offset);
          }
          return newArr;
        };
        this.origin.x = setOffset(this.mag_x, this.offset.x);
        this.origin.y = setOffset(this.mag_y, this.offset.y);
        this.origin.z = setOffset(this.mag_z, 0);
      }
      // オフセット
      this.offset_xaxis = this.offset.x;
      this.offset_yaxis = this.offset.y;
    },
    clear() {
      this.mag_x = [];
      this.mag_y = [];
      this.mag_z = [];
      this.update();
    },
    setOffset(offsetX, offsetY) {
      let setOffset = function(arr, offset) {
        let newArr = [];
        for (let i = 0; i < arr.length; i++) {
          newArr.push(arr[i] - offset);
        }
        return newArr;
      };
      this.offset = {
        x: offsetX,
        y: offsetY
      };
      this.origin.x = setOffset(this.mag_x, this.offset.x);
      this.origin.y = setOffset(this.mag_y, this.offset.y);
      this.origin.z = setOffset(this.mag_z, 0);
      // オフセットを保存
      this.saveOffset(offsetX, offsetY);
    },
    offsetClear() {
      this.offset = {
        x: 0,
        y: 0
      };
      this.origin.x = [];
      this.origin.y = [];
      this.origin.z = [];
      // オフセットクリア
      this.saveOffset(0, 0);
    },
    offsetCalc() {
      if (this.mag_x.length <= 0 || this.mag_y.length <= 0) {
        return;
      }
      let arrayMinMax = function(arr) {
        let maxVal = null;
        let minVal = null;
        for (let i = 0; i < arr.length; i++) {
          if (maxVal == null || arr[i] > maxVal) {
            maxVal = arr[i];
          }
          if (minVal == null || arr[i] < minVal) {
            minVal = arr[i];
          }
        }
        return {
          min: minVal,
          max: maxVal,
          offset: (minVal + maxVal) / 2
        };
      };
      let xCalc = arrayMinMax(this.mag_x);
      let yCalc = arrayMinMax(this.mag_y);
      // x軸
      this.max_xaxis = xCalc.max;
      this.min_xaxis = xCalc.min;
      this.offset_xaxis = xCalc.offset;
      // y軸
      this.max_yaxis = yCalc.max;
      this.min_yaxis = yCalc.min;
      this.offset_yaxis = yCalc.offset;
      // オフセット設定
      this.setOffset(xCalc.offset, yCalc.offset);
    },
    saveOffset(offset_x, offset_y) {
      let client = this.$refs.rosObject.createService(
        "/legmin_himawari_mi/save_mi_offset",
        "/legmin_himawari_mi/save_mi_offset"
      );

      let request = this.$refs.rosObject.createServiceRequestWithXY(
        offset_x,
        offset_y
      );
      client.callService(request, function(result) {
        console.log("Result for service call on " + result);
      });
    },
    saveAzimuthOffset(offset) {
      console.log(offset);
      let client = this.$refs.rosObject.createService(
        "/legmin_himawari_mi/save_azimuth_offset",
        "/legmin_himawari_mi/save_azimuth_offset"
      );

      let request = this.$refs.rosObject.createServiceRequest(offset);
      client.callService(request, function(result) {
        console.log("Result for service call on " + result);
      });
    },
    saveInclinationAngleOffset(offset_x, offset_y) {
      let client = this.$refs.rosObject.createService(
        "/legmin_himawari_imu/save_inclination_angle_offset",
        "/legmin_himawari_imu/save_inclination_angle_offset"
      );
      let request = this.$refs.rosObject.createServiceRequestWithXY(
        offset_x,
        offset_y
      );
      client.callService(request, function(result) {
        console.log("Result for service call on " + result);
      });
    },
    setMagneticField() {
      let _this = this;

      const listener = this.$refs.rosObject.createTopic(
        "/legmin_himawari_mi/magnetometer"
      );
      listener.subscribe(function(message) {
        _this.magnetic_field = message.magnetic_field;
        _this.mag_x.push(_this.magnetic_field.x);
        _this.mag_y.push(_this.magnetic_field.y);
        _this.mag_z.push(_this.magnetic_field.z);
        listener.unsubscribe();
      });
    },
    setAzimuth() {
      let _this = this;

      const listener = this.$refs.rosObject.createTopic(
        "/legmin_himawari_mi/magnetism_azimuth"
      );
      listener.subscribe(function(message) {
        _this.azimuth = message.data;
        _this.polar.theta = [0, _this.azimuth];
        listener.unsubscribe();
      });
    },
    setTargetAzimuth() {
      let _this = this;

      const listener = this.$refs.rosObject.createTopic(
        "/legmin_himawari_navigation/target_azimuth"
      );
      listener.subscribe(function(message) {
        _this.targetAzimuth = message.data;
        _this.targetPolar.theta = [0, _this.targetAzimuth];
        listener.unsubscribe();
      });
    },
    initOffset() {
      let _this = this;

      const listener = this.$refs.rosObject.createTopic(
        "/legmin_himawari_mi/mi_offset"
      );
      listener.subscribe(function(message) {
        _this.offset.x = message.offset_x;
        _this.offset.y = message.offset_y;
        listener.unsubscribe();
      });
    },
    run(run) {
      console.log("run is " + run);
      let runClient = this.$refs.rosObject.createActionClient(
        "/legmin_himawari_auto_calibration/server_mi_calibration",
        "/legmin_himawari_auto_calibration/MiCalibrationAction"
      );

      let goal = this.$refs.rosObject.createGoal(runClient, { run: run });

      goal.on("feedback", function(feedback) {
        console.log("Feedback: ", feedback);
      });

      goal.on("result", function(result) {
        console.log("Final Result: ", result);
      });

      goal.send();
    },
    stop(run) {
      console.log("run");
      let runClient = this.$refs.rosObject.createActionClient(
        "/legmin_himawari_auto_calibration/server_mi_calibration",
        "/legmin_himawari_auto_calibration/MiCalibrationAction"
      );

      let goal = this.$refs.rosObject.createGoal(runClient, { run: run });

      goal.on("feedback", function(feedback) {
        console.log("Feedback: ", feedback);
      });

      goal.on("result", function(result) {
        console.log("Final Result: ", result);
      });

      goal.send();
    },
    uneRun: function(run) {
      let runClient = this.$refs.rosObject.createActionClient(
        "/legmin_himawari_lidar/server_navigation_run",
        "/legmin_himawari_lidar/NavigationRunAction"
      );

      let goal = this.$refs.rosObject.createGoal(runClient, { run: run });

      goal.on("feedback", function(feedback) {
        console.log("Feedback: ", feedback);
      });

      goal.on("result", function(result) {
        console.log("Final Result: ", result);
      });

      goal.send();
    },
    stationIn: function(run) {
      let runClient = this.$refs.rosObject.createActionClient(
        "/legmin_himawari_run_to_station/server_station_in",
        "/legmin_himawari_run_to_station/StationInAction"
      );

      let goal = this.$refs.rosObject.createGoal(runClient, { run: run });

      goal.on("feedback", function(feedback) {
        console.log("Feedback: ", feedback);
      });

      goal.on("result", function(result) {
        console.log("Final Result: ", result);
      });

      goal.send();
    },
    stationOut: function(run) {
      let runClient = this.$refs.rosObject.createActionClient(
        "/legmin_himawari_run_to_station/server_station_out",
        "/legmin_himawari_run_to_station/StationOutAction"
      );

      let goal = this.$refs.rosObject.createGoal(runClient, { run: run });

      goal.on("feedback", function(feedback) {
        console.log("Feedback: ", feedback);
      });

      goal.on("result", function(result) {
        console.log("Final Result: ", result);
      });

      goal.send();
    },
    setInclinationAngle() {
      let _this = this;

      const listener = this.$refs.rosObject.createTopic(
        "/legmin_himawari_imu/inclination_angle"
      );
      listener.subscribe(function(message) {
        _this.inclination_angle = message.linear;
        listener.unsubscribe();
      });
    },
    setInclinationAngleOffset() {
      let _this = this;

      const listener = this.$refs.rosObject.createTopic(
        "/legmin_himawari_imu/inclination_angle_offset"
      );
      listener.subscribe(function(message) {
        _this.offset_x_angle = message.offset_x;
        _this.offset_y_angle = message.offset_y;
        listener.unsubscribe();
      });
    },
    setIntervalMagneticField() {
      let _this = this;

      this.magneticFieldIntervalID = setInterval(function() {
        _this.setMagneticField();
        _this.setAzimuth();
        _this.setTargetAzimuth();
        _this.offsetCalc();
        _this.update();
      }, 500);
    },
    clearIntervalMagneticField() {
      clearInterval(this.magneticFieldIntervalID);
    },
    setIntervalInclinationAngle() {
      let _this = this;

      this.inclinationAngleIntervalID = setInterval(function() {
        _this.setInclinationAngle();
        _this.setInclinationAngleOffset();
      }, 500);
    },
    clearIntervalInclinationAngle() {
      clearInterval(this.inclinationAngleIntervalID);
    }
  },
  beforeDestroy() {
    if (this.ros) {
      this.ros.close();
    }
  }
};
</script>

<style>
.row {
  padding-top: 10px;
  padding-bottom: 10px;
}
.btn {
  margin-left: 10px;
}
</style>
